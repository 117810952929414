/* GLOBAL */
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap');
body {
  margin: 0;

  font-family: "Roboto", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
}


body, html {
  margin: 0;
  padding: 0;
  overflow-x: hidden;

  background-color: #fff; /* Set the background color of the entire page */
}
h1, h2, h3, h4, h5, h6, p {
  margin: 0;
}

:root {
  --primary: #7da0ff;
  --heading-color: #2f3594;
  --bg-shade: #d5d8d9;
  --darkblue: #202265;
  --black: #000000;
  --white: #ffffff;
}

.btn {
  font-family: "Roboto", sans-serif;
  display: inline-block;
  padding: 15px;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  cursor: pointer;
  text-align: center;
  text-decoration: none;
  border-radius: 4px;
  transition: background-color 0.5s;
  margin: 10px;
}

.btn-outline-primary {
  color: var(--primary);
  background-color: var(--white);
  border: 1px solid var(--primary);
}

.btn-outline-primary:hover {
  color: var(--white);
  background-color: var(--primary);
}

.btn-primary {
  color: var(--white);
  background-color: var(--primary);
  border: 1px solid var(--primary);
}

.btn-primary:hover {
  color: var(--primary);
  background-color: var(--white);
}

/* Global ends */

/* Main heading */

h1 {
  font-size: 56px;
  font-weight: 700;
  line-height: 67px;
}

h2 {
  font-size: 48px;
  font-weight: 700;
  line-height: 58px;
}

h3 {
  font-size: 24px;
  font-weight: 700;
  line-height: 34px;
}

/* body */

.text-lg {
  color: var(--darkblue);
  font-size: 18px;
  font-weight: 400;
  font-style: normal;
  line-height: 27px;
}

.text-md {
  color: var(--darkblue);
  font-size: 16px;
  font-weight: 400;
  font-style: normal;
  line-height: 24px;
}

.text-sm {
  color: var(--black);
  font-size: 14px;
  font-weight: 400;
  font-style: normal;
  line-height: 21px;
}

/* Section  Title */

/* skills and about me */

.section--title {
  color: var(--heading-color);
  font-size: 24px;
  text-align: center;
  font-weight: 700;
  font-style: normal;
  line-height: 32px;
  display: flex;
  align-items: flex-start;
}

/* testimonials, Home page, Contact Me */

.sub--title {
  color: var(--heading-color);
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
}
/* NavBar */

.back-to-top {
  position: fixed;
  bottom: 20px;
  right: 20px;
  background-color: #007bff;
  border-radius: 50%;
  padding: 10px;
  cursor: pointer;
  z-index: 1000;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  display: flex;
  align-items: center;
  justify-content: center;
}

.back-to-top:hover {
  background-color: #0056b3;
}


/* NavBar */
.navbar {
  position: fixed; /* Fix the navbar to the top */
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--white);
  box-shadow: 0px 5.333px 80px 0 rgba(0, 0, 0, 0.1);
  z-index: 1000; /* Ensure it's on top of other content */
  height: 80px;
  transition: background-color 0.3s ease-in-out;
}


.navbar-scroll {
  background-color: var(--white);
}

.navbar-nav {
  display: flex;
  align-items: center;
  gap: 70px; /* Adjusted space between nav links */
}

.navbar-nav .nav-item {
  display: flex;
  align-items: center;
}

.navbar-nav .nav-link {
  text-decoration: none;
  color: var(--darkblue);
  font-size: 20px;
  font-weight: 400;
  line-height: 24px;
  cursor: pointer;
}

.navbar-nav .nav-link:hover {
  color: var(--primary);
}

.navbar-nav .btn-outline-primary {
  margin-left: 15px;
  display: flex;
  align-items: center;
}

.navbar--active-content {
  color: var(--primary);
  margin: 10px;
}

/* Hamburger */
.navbar-toggler {
  border: none;
  background: transparent;
  padding: 0;
  display: flex;
  align-items: center;
}

.navbar-toggler-icon {
  display: block;
  width: 24px;
  height: 3px;
  position: relative;
  transition: all 0.3s ease-in-out;
}

.navbar-toggler-icon::before,
.navbar-toggler-icon::after {
  content: '';
  width: 24px;
  height: 3px;
  position: absolute;
  left: 0;
  transition: all 0.3s ease-in-out;
}

.navbar-toggler-icon::before {
  top: -5px;
}

.navbar-toggler-icon::after {
  top: 5px;
}

/* Responsive styles */
@media (max-width: 1200px) {
  .navbar-nav {
    flex-direction: column;
    gap: 10px;
  }

  .navbar-nav .nav-item {
    margin: 5px 0;
  }

  .navbar-nav .btn-outline-primary {
    margin-left: 0;
  }
}

@media (max-width: 768px) {
  .navbar {
    flex-direction: column;
    align-items: center;
    height: auto;
  }

  .navbar-nav {
    width: 100%;
    padding: 10px 0;
    justify-content: center;
  }

  .navbar-nav .nav-item {
    justify-content: center;
  }

  .navbar-nav .nav-link {
    padding: 10px 20px;
    width: 100%;
    text-align: center;
  }

  .navbar-toggler {
    align-self: center;
  }
}


/* NavBar ends */
/* Carousel Item */
.hero--carousel-item {
  position: relative;
  width: 100%;
  height: auto;
}

/* For single wide image */
.hero--carousel-item img {
  width: 100%;
  height: 60vh;
  object-fit: contain;
  border-radius: 8px;  
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.2);
  margin: 5px auto;
  display: block;
}

/* Side-by-side images */
.half-width-image {
  width: 100%;
  padding: 0 5px;
}

.half-width-image img {
  width: 100%;
  height: 60vh;
  object-fit: contain;
  border-radius: 8px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.2);
}

/* Adjusting carousel icons */
/* .carousel-control-prev,
.carousel-control-next {
  opacity: 1;
  width: 10%; 
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  background-color: rgba(0, 0, 0, 0.5);
  opacity: 1;
  width: 60px;
  height: 50px;
}

.carousel-control-prev:hover,
.carousel-control-next:hover {
  opacity: 1;
} */

/* Media Queries */
@media (max-width: 768px) {
  .hero--carousel-item img {
    height: 50vh; 
  }
  
  .half-width-image {
    width: 100%;
    padding: 5px 0;
  }
}

@media (max-width: 480px) {
  .hero--carousel-item img {
    height: 40vh;
  }
}

.hero--section {
  position: relative;
  width: 100%;
  height: auto; 
  overflow: hidden;
  padding: 0; /* Removed extra padding */

}

.hero--section .hero--carousel-item {
  position: relative;
  width: 100%;
  height: auto;
}

.hero--section .hero--carousel-item img {
  width: 100%;
  height: 60vh;
  object-fit: contain;
  border-radius: 8px;  
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.2);
  margin: 5px auto;  
  display: block; 
 }

/* Positioning the carousel control icons */
.hero--section .carousel-control-prev,
.hero--section .carousel-control-next {
  opacity: 1;

  width: 7%; /* Reduce the size of the control area */
}

.hero--section .carousel-control-prev-icon,
.hero--section .carousel-control-next-icon {
  background-color: rgba(0, 0, 0, 0.5);
opacity: 1;
  width: 50px;
  height: 40px;
}

.hero--section .carousel-control-prev {
  left: 0; /* Position the arrow on the far left */
}

.hero--section .carousel-control-next {
  right: 0; /* Position the arrow on the far right */
}

.hero--section .carousel-control-next-icon:hover,
.hero--section .carousel-control-prev-icon:hover {
  background-color: rgba(0, 0, 0, 0.8);
}

.hero--section .hero--carousel-caption {
  position: absolute;
  bottom: 20px;
  left: 50%;
  width: 100%;
  transform: translateX(-50%);
  text-align: center;
  background-color: rgba(0, 0, 0, 0.5);
  padding: 20px;
  border-radius: 10px;
  color: white;
}

.hero--section .section--title {
  color: #fff;
  font-size: 24px;
  margin-bottom: 10px;
  justify-content: center;
}

.hero--section .hero--section--title {
  color: #fff;
  font-size: 48px;
  font-weight: 700;
  line-height: 58px;
}

.hero--section .hero--section--description {
  color: #fff;
  font-size: 20px;
  font-weight: 450;
  line-height: 28px;
}

.hero--section .btn-primary {
  background-color: var(--primary);
  border-color: var(--primary);
}

.hero--section .btn-primary:hover {
  background-color: var(--white);
  color: var(--primary);
}

/* Media Queries */
@media (max-width: 768px) {
  .hero--section .hero--carousel-item img {
    width: 100%; /* Use full width on smaller screens */
    height: 50vh; /* Adjusted height */
  }
  
  .hero--section .hero--section--title {
    font-size: 32px;
    line-height: 40px;
  }

  .hero--section .hero--section--description {
    font-size: 17px;
    line-height: 24px;
  }

  .hero--section .btn-primary {
    padding: 10px 20px;
    font-size: 14px;
  }
}

@media (max-width: 480px) {
  .hero--section .hero--carousel-item img {
    width: 100%; /* Ensure image takes full width */
    height: 40vh; /* Further reduced height */
  }

  .hero--section .hero--section--title {
    font-size: 24px;
    line-height: 32px;
  }

  .hero--section .hero--section--description {
    font-size: 14px;
    line-height: 20px;
  }

  .hero--section .btn-primary {
    padding: 8px 16px;
    font-size: 12px;
  }
}



/* Reviews */
.reviews--section {
  background-color: #f9f9f9;
  padding: 40px 0;
}

.reviews--section h2 {
  color: #202265;
  font-weight: bold;
  margin-bottom: 20px;
}

.reviews--section p {
  font-size: 16px;
  color: #555;
  margin-bottom: 15px;
}

.reviews--section .btn {
  margin-top: 10px;
}

/* Areas */

.areas--section {
  background-color: #eef3f8; /* Light blue/gray background */
  padding: 40px 10px; /* Spacing around the section */
  border-radius: 10px; /* Rounded corners */
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1); /* Subtle shadow */
}

.areas--section h2 {
  font-size: 32px; /* Larger heading */
  font-weight: 700;
  color: #1f3c88; /* Dark blue color for the heading */
  text-align: center;
  margin-bottom: 30px; /* Space below heading */
}

.areas--list {
  display: flex;
  justify-content: center; /* Center the list horizontally */
  flex-wrap: wrap; /* Allow items to wrap on smaller screens */
  gap: 15px; /* Spacing between items */
  list-style: none;
  padding: 0;
  margin: 0;
}

.areas--list li {
  font-size: 20px; /* Larger text */
  color: #1f3c88; /* Dark blue text color */
  background-color: #ffffff; /* White background for the boxes */
  border: 2px solid #1f3c88; /* Blue border */
  border-radius: 8px; /* Rounded corners */
  padding: 15px 25px; /* Space inside the box */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Subtle shadow for each item */
  transition: transform 0.3s ease, background-color 0.3s ease; /* Smooth hover effects */
}

.areas--list li:hover {
  background-color: #1f3c88; /* Dark blue on hover */
  color: #fff; /* White text on hover */
  transform: translateY(-5px); /* Lift up effect on hover */
}

/* Responsive Styling for Smaller Screens */
@media (max-width: 768px) {
  .areas--list {
    flex-direction: column; /* Stack the items vertically */
    gap: 10px; /* Smaller spacing */
  }

  .areas--section h2 {
    font-size: 28px; /* Smaller heading for mobile */
  }

  .areas--list li {
    font-size: 18px;
    padding: 10px 20px; /* Reduce padding on mobile */
  }
}

/* Map */
.map--section {
  margin-top: 30px;
  text-align: center;
}

.leaflet-container {
  height: 500px;
  width: 100%;
  border-radius: 8px;
  margin: 20px 0;
}


/* Services Section Begins */

.services--section {
  padding: 0;
  margin: 0;
  margin-top: 20px;
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.services--section--heading {
  color: rgb(51, 101, 188);
  font-size: 44px;
  font-weight: 500;
  line-height: 67px;
}

.services--section--container {
  display: grid;
  justify-content: center;
  align-items: center;
  gap: 42.6px;
  grid-template-columns: repeat(3, 1fr); /* 3 cards in a row */
}

.services--section--card {
  display: flex;
  padding: 32px;
  flex-direction: column;
  align-items:flex-end;
  border-radius: 10.6px;
  background: white;

  min-height: 450px; /* Ensure all cards have the same minimum height */
  justify-content: space-between; 
}

.services--section--card:hover {
  border-bottom: 4px solid var(--primary);
}

.services--section--card:hover .services--section--description {
  color: var(--darkblue);
}

.services--section--img {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 13.3px;
  height: 200px;
  background: #fff;
}

.services--section--card--content {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  align-self: stretch;
}

.services--section--title {
  color: var(--heading-color);
  font-size: 30px;
  font-weight: 700;
  line-height: 45px;
}

.services--section--description {
  color: var(--black);
  font-size: 21.3px;
  font-weight: 400;
  font-style: normal;
  line-height: 32px;
}

@media (max-width: 1200px) {
  .services--section--container {
    grid-template-columns: repeat(2, 1fr); /* 2 cards for medium screens */
  }
}

@media (max-width: 768px) {
  .services--section--container {
    grid-template-columns: 1fr; /* Single column for mobile */
  }

  .services--section--img, .services--section--card--content {
    width: 100%;
    max-width: none;
  }

  .services--section--img img {
    width: 100%;
    height: auto;
  }
  
  .services--section--heading {
    font-size: 48px;
    line-height: 58px;
  }

  .services--section--card {
    align-items: center;
    text-align: center;
  }

  .services--section--card--content {
    align-items: center;
  }
}

/* Serving */



/* Gradient background for each card */
.card {
  background: linear-gradient(135deg, #f9fbff, #dfe4ff); /* Light gradient for cards */
  border: none; /* Remove border */
  border-radius: 12px; /* Optional: rounded corners */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Optional: Add some shadow */
  transition: transform 0.3s ease; /* Smooth hover effect */
}

/* Hover effect for cards */
.card:hover {
  transform: scale(1.05); /* Slight zoom on hover */
}

/* Centering text inside cards */
.card-title {
  font-size: 1.25rem;
  font-weight: bold;
  color: #333; /* Darker text color */
}

.btn-primary {
  background-color: rgb(68, 137, 227);
  border-color: #4a90e2;
}

.btn-primary:hover {
  background-color: #357ABD;
  border-color: #357ABD;
}



/* About */

.about--section {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 104.6px;
  padding: 90.3px 60.3px;
  align-items: center;
}

.aboutme--section--img {
  display: flex;
  justify-content: center;  /* Center horizontally */
  align-items: center;  /* Center vertically */
}

.aboutme--section--img > img {
  width: 100%;
  height: auto;
  max-height: 450px; 
  object-fit: contain; /* Ensures the image fits without cutting off */
  background-color: white; /* Optional: Set a white background */
}


.about--section--description {
  font-size: 1.2em;
  line-height: 1.6; 
}

@media (max-width: 768px) {
  .about--section {
    grid-template-columns: 1fr;
    padding: 50px 20px;
    gap: 50px;
  }

  .aboutme--section--img {
    justify-content: center;
  }

  .aboutme--section--img > img {
    width: 100%;
    height: auto;
  }
}

/* About Us Details */
.about--section {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 114.6px;
  padding: 90.3px 60.3px;
  align-items: center;
}

.about--section--description {
  font-size: 1.2em;
  line-height: 1.6;
}

.highlight-blue {
  color: rgb(51, 101, 188);
  font-weight: bold;
}

.about-section--heading {
  color: rgb(51, 101, 188);
}

.about--section--img > img {
  width: 100%;
  height: auto;
  max-height: 400px;
  object-fit: cover;
}

/* Meet Our Inspector Section */

.about--inspector--section {
  display: flex;
  flex-direction: column;  
gap: 150px;
  margin: 50px auto;
}

.about--inspector--section--img img {
  width: 350px; /* Adjust image size */
  height: auto;
  margin-bottom: 10px;
  margin-left: 25%; 
 text-align: center;
}

.about--inspector--section--img{
  text-align: center;
}
.inspector-caption {
  margin-left: 25%; 
  font-size: 22px;
  font-weight: bold;
  margin-top: 10px; /* Space above the caption */
  color:  rgb(51, 101, 188)
}
.meet--inspector--section {
  padding: 50px 0;
}

.meet--inspector--section h2 {
  color: var(--primary);
  font-size: 2em;
  font-weight: bold;
  margin-bottom: 20px;
}

.inspector--image {
  max-width: 100%;
  height: auto;
  border-radius: 10px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.2);
  text-align: center;
}

/* Media Queries */
@media (max-width: 768px) {
  .about--section {
    grid-template-columns: 1fr;
    padding: 50px 20px;
    gap: 50px;
  }

  .about--inspector--section--img img {
    width: 70%; /* Reduce image size on smaller screens */
    margin-left: auto;
    margin-right: auto;
  }

  .inspector-caption {
    text-align: center; /* Center caption on small screens */
    margin-left: 0; /* Remove the extra left margin */
  }
  .about--section--img > img {
    width: 100%;
    height: auto;
  }

  .meet--inspector--section {
    text-align: center;
  }

  .meet--inspector--section .col-md-6 {
    margin-bottom: 20px;
  }

  .inspector--image {
    width: 70%;
    margin: 0 auto;
  }
}



/* Testimonial */
.testimonial--section {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 50px 85px;
  gap: 40px;
}
.testimonial--container-box {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}
.testimonial--container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
}
.testimonial--section--container {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  gap: 20px;
  flex-wrap: wrap;
  width: 100%;
}
.testimonial--section--card {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  width: 300px;
  background: #ffffff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  overflow: hidden;
  text-align: center;
  margin: 10px;
  flex: 1;
}
.testimonial--section--img {
  width: 100%;
  height: 200px;
  object-fit: cover;
}
.testimonial--section--card-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  gap: 10px;
}
.testimonial--section--title {
  font-size: 1.2em;
  margin: 0;
  color: #333;
}
.text-md {
  font-size: 1em;
  color: #555;
}
.testimonial--section--button {
  font-size: 0.9em;
  color: #007bff;
  text-decoration: none;
  display: inline-flex;
  align-items: center;
  margin-top: 10px;
}
.testimonial--section--button:after {
  content: '↗';
  margin-left: 5px;
  font-size: 1em;
}
.testimonial--section--title {
  color: var(--heading-color);
}
.testimonial--section--card:hover path {
  stroke: #7da0ff;
}

/* PriceList */
.text-small {
  font-size: 0.8rem; /* Makes Excluding HST smaller */
  color: gray;
}
.d-block {
  display: block; /* Ensures Excluding HST is on its own line */
}




.price-list-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 50px 0;
}

.price-list-heading {
  font-size: 40px;
  font-weight:bolder;
  color: rgb(51, 101, 188);
  margin-bottom: 30px;
}

.price-list-table-container {
  width: 100%; 
  max-width: 1000px; 
  margin: 0 auto; 
}

.table {
  background-color: white;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.table th,
.table td {
  padding: 20px;
  font-size: 20px;
}

@media (max-width: 768px) {
  .price-list-table-container {
    width: 100%; /* Ensure it takes full width on smaller screens */
    max-width: 100%; /* Remove max width limit for smaller screens */
  }

  .price-list-heading {
    font-size: 28px;
  }

  .table th,
  .table td {
    padding: 15px;
    font-size: 18px;
  }
}

/* Footer */
/* Pushes the footer to the bottom */

.footer--container { 
   margin-top: auto;
  background-color: snow;
  color: rgb(51, 101, 188);
  padding: 40px 0;
  font-family: "Roboto", sans-serif;
  position: relative; /* Added for corner images positioning */
}
.email-link {
  text-decoration: none;
  color: inherit;
}

.footer--link--container {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 0 30px;
  max-width: 1200px;
  margin: 0 auto;
}

.footer--logo--section img {
  width: 150px;
  margin-bottom: 15px;
}

.footer--logo--section p {
  max-width: 250px;
  font-size: 14px;
  margin-bottom: 15px;
}

.footer--button {
  background-color: darkblue;
  color: rgb(51, 101, 188);
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 14px;
}

.footer--items {
  margin: 0 20px;
  gap: 40px;
}

.footer--items h4 {
  margin-bottom: 15px;
  font-size: 18px;
  font-weight: bold;
}

.footer--items ul {
  list-style: none;
  padding: 0;
}

.footer--items ul li {
  margin-bottom: 10px;
}

.footer--items ul li a {
  color: rgb(51, 101, 188);
  text-decoration: none;
  font-size: 14px;
  transition: color 0.3s;
}

.footer--items ul li a:hover {
  color: rgb(23, 53, 103);
}

.footer--social--icon ul {
  list-style: none;
  justify-content: center;
  display: flex;
  gap: 15px;
  padding: 0;
}

.footer--social--icon ul li {
  display: inline-block;
}

.footer--social--icon ul li a {
 color: rgb(51, 101, 188);
  transition: color 0.3s;
}

.footer--social--icon ul li a:hover {
  color: darkblue;
}

.divider {
  margin: 20px 0;
  border: 0;
  border-top: 1px solid darkblue;
}

.footer--content--container {
  text-align: center;
  padding: 10px 0;
}

.footer--content--container p {
  margin: 0;
  font-size: 14px;
  font-weight: bold;
  font-family: Cambria;
}

/* Corner images */
.corner-image-left,
.corner-image-right {
  position: absolute;
  bottom: 10px;
  width: 95px;
  height: 90px;
}

.corner-image-left {
  left: 8%;
}

.corner-image-right {
  right: 8%;
}

@media (max-width: 768px) {
  .footer--link--container {
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  .footer--logo--section,
  .footer--items,
  .footer--social--icon {
    margin: 20px 0;
  }

  /* Adjust corner image size for mobile */
  .corner-image-left,
  .corner-image-right {
    width: 75px;
    height: 80px;
  }
}

/* Gallery */
.gallery--section {
  padding: 20px 0;
  text-align: center; /* Ensure the text and elements within the gallery are centered */
}

.gallery--container {
  max-width: 80%; /* Adjust the maximum width of the gallery container */
  margin: 0 auto; /* Center the gallery container */
}

.gallery-image {
  width: auto; /* Adjust the width automatically */
  max-height: 500px; /* Set the maximum height for images */
  object-fit: contain; /* Ensure images maintain their aspect ratio without being stretched */
  margin: 0 auto; /* Center the images horizontally */
  display: block;
}

@media (max-width: 768px) {
  .gallery--container {
    max-width: 100%;
    padding: 0 20px;
  }

  .gallery-image {
    width: 100%; /* Set the images to be fully responsive on smaller screens */
    max-height: auto; /* Remove the fixed height on smaller screens */
  }
}


/* contact me */
/* For wider button on mobile */
.btn-primary {
  width: 100%; /* Makes the button full width on smaller screens */
  padding: 10px 20px;
 }

@media (min-width: 768px) {
  .btn-primary {
      width: auto; /* Auto width for larger screens */
      padding: 10px 40px;
      align-items: center;
  }
}

/* Contact Me Section */
.contact-me-section {
  background-color: #f9f9f9;
  padding: 60px 20px;
}

.contact-heading {
  font-size: 2.5rem;
  color: #343a40;
  margin-bottom: 20px;
}

.contact-message {
  font-size: 1.2rem;
  color: #6c757d;
  line-height: 1.6;
  margin-bottom: 30px;
}

@media (max-width: 768px) {
  .contact-heading {
      font-size: 2rem;
  }

  .contact-message {
      font-size: 1rem;
  }
}

